import { defineStore } from 'pinia';
import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';

import type { INotificacion } from '@/interfaces';

const BASE_URL = `https://db.mibiotren.cl/notificaciones`;

PouchDB.plugin(PouchDBFind);

export const useNotificacionesStore = defineStore('notificaciones', () => {
  const notificaciones = ref<Map<string, INotificacion>>(new Map());
  const loading = ref(false);
  let NotificacionesRemote: PouchDB.Database<INotificacion> | null = null;
  const timeoutNotifs = ref<any>(null);
  const notifs = computed(() => Array.from(notificaciones.value.values()));

  async function getNotificaciones() {
    if (!NotificacionesRemote) {
      NotificacionesRemote = new PouchDB(BASE_URL);
    }
    if (loading.value) return;
    loading.value = true;
    const dbNotifs = await NotificacionesRemote.find({
      selector: { active: true },
    })
      .then((d) => d.docs.forEach((n) => notificaciones.value.set(n._id, n)))
      .catch((e) => [])
      .finally(() => (loading.value = false));

    return dbNotifs;
  }

  onMounted(() => {
    console.log(notifs.value);
    timeoutNotifs.value = setTimeout(() => getNotificaciones(), 60e3 * 1);
    getNotificaciones();
  });

  onUnmounted(() => clearTimeout(timeoutNotifs.value));

  return {
    getNotificaciones,
    notificaciones: notifs,
  };
});