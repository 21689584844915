import { default as indexARmdI4DwT7Meta } from "/app/pages/[servicio]/index.vue?macro=true";
import { default as mapac4vajFcORcMeta } from "/app/pages/[servicio]/mapa.vue?macro=true";
import { default as saldocQDDtXmpM9Meta } from "/app/pages/[servicio]/saldo.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexVwSoYzbFsbMeta } from "/app/pages/[servicio]/index?macro=true";
import { default as mapa3V9nmaK8qgMeta } from "/app/pages/[servicio]/mapa?macro=true";
import { default as saldoqlNc1mm3sZMeta } from "/app/pages/[servicio]/saldo?macro=true";
export default [
  {
    name: "servicio",
    path: "/:servicio()",
    component: () => import("/app/pages/[servicio]/index.vue").then(m => m.default || m)
  },
  {
    name: "servicio-mapa",
    path: "/:servicio()/mapa",
    component: () => import("/app/pages/[servicio]/mapa.vue").then(m => m.default || m)
  },
  {
    name: "servicio-saldo",
    path: "/:servicio()/saldo",
    component: () => import("/app/pages/[servicio]/saldo.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexVwSoYzbFsbMeta?.name,
    path: "/:servicio(biotren|laja-talcahuano|victoria-temuco)/:idsalida?/:idllegada?",
    component: () => import("/app/pages/[servicio]/index").then(m => m.default || m)
  },
  {
    name: mapa3V9nmaK8qgMeta?.name,
    path: "/:servicio(biotren|laja-talcahuano|victoria-temuco)/mapa",
    component: () => import("/app/pages/[servicio]/mapa").then(m => m.default || m)
  },
  {
    name: saldoqlNc1mm3sZMeta?.name,
    path: "/:servicio(biotren|laja-talcahuano|victoria-temuco)/saldo",
    component: () => import("/app/pages/[servicio]/saldo").then(m => m.default || m)
  }
]