import revive_payload_client_HgERooYJdL from "/app/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.2_eslint@9.9.1_rollup@2.79.1_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_cgvJVnGfBF from "/app/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.2_eslint@9.9.1_rollup@2.79.1_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_pnceRdRqMO from "/app/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.2_eslint@9.9.1_rollup@2.79.1_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_8hlF7a2zTV from "/app/node_modules/.pnpm/nuxt-site-config@2.2.16_rollup@2.79.1_vite@5.4.3_vue@3.4.38/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_nlVX3WXS1C from "/app/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.2_eslint@9.9.1_rollup@2.79.1_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_fwvRudX94O from "/app/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.2_eslint@9.9.1_rollup@2.79.1_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_FVSfCPWP5Z from "/app/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.2_eslint@9.9.1_rollup@2.79.1_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_qwqyr19QgI from "/app/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.2_eslint@9.9.1_rollup@2.79.1_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_GHMmnH2ztT from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@2.79.1_typescript@5.5.4_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_h5aIusfYZD from "/app/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.2_eslint@9.9.1_rollup@2.79.1_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_DuvHFsc9IM from "/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.7_rollup@2.79.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_iUptKp2QIn from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.3_@vite-pwa+assets-generator@0.2.5_rollup@2.79.1_vite@5.4.3_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_client_EcClhhybSi from "/app/node_modules/.pnpm/nuxt-gtag@2.1.0_rollup@2.79.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_client_pslVf07cCK from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_rollup@2.79.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import umami_wsmGDYyzCw from "/app/node_modules/.pnpm/nuxt-umami@2.6.4/node_modules/nuxt-umami/plugins/umami.ts";
import _2_pouchdb_client_rNyFaRK3ZK from "/app/plugins/2.pouchdb.client.ts";
import axios_QMFgzss1s4 from "/app/plugins/axios.ts";
export default [
  revive_payload_client_HgERooYJdL,
  unhead_cgvJVnGfBF,
  router_pnceRdRqMO,
  _0_siteConfig_8hlF7a2zTV,
  payload_client_nlVX3WXS1C,
  navigation_repaint_client_fwvRudX94O,
  check_outdated_build_client_FVSfCPWP5Z,
  chunk_reload_client_qwqyr19QgI,
  plugin_vue3_GHMmnH2ztT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_h5aIusfYZD,
  nuxt_plugin_DuvHFsc9IM,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_iUptKp2QIn,
  plugin_client_EcClhhybSi,
  plugin_client_pslVf07cCK,
  umami_wsmGDYyzCw,
  _2_pouchdb_client_rNyFaRK3ZK,
  axios_QMFgzss1s4
]