import { defineStore } from 'pinia';
import { useNuxtApp, navigateTo } from '#app';

import { PromiseReplicate } from '@/helpers/replicate';
import type { Horario, IEstacion, IHorarios, ITarifa } from '@/interfaces';
import { useServiciosStore } from './servicios';

let Horarios: null | PouchDB.Database<IHorarios> = null;
let HorariosRemote: null | PouchDB.Database<IHorarios> = null;

interface HorarioAPI {
  salida?: string;
  llegada?: string;
  external?: boolean;
}



export const useHorarioStore = defineStore(
  'horarios',
  function HorariosState() {
    const config = useRuntimeConfig();
    const { $db } = useNuxtApp();

    const BASE_URL = config.public.dbUrl || 'https://db.mibiotren.cl';
    const version = 'v4';

    const servicios = useServiciosStore();

    const salida = ref('16');
    const llegada = ref('35');
    const horarios = ref<Horario[]>([]);
    const horariosVuelta = ref<Horario[]>([]);
    const loading = ref(false);
    const initLoad = ref(0);
    const isBuscandoHorario = ref(false);
    const servicioId = computed(() => servicios.newServicio);

    // const isBiotren = computed(() => servicioId.value === '1');
    // const isLinea2Salida = computed(() => parseInt(salida.value) <= 35 && parseInt(salida.value) >= 23);
    // const isLinea2Llegada = computed(() => parseInt(llegada.value) <= 35 && parseInt(llegada.value) >= 23);

    onMounted(() => {
      initDB();
      syncHorarios().catch((e) => {
        if (e?.catch) {
          return;
        } else console.log(e, 'Sync Horarios Error');
      });
    });

    function initDB() {
      if (!HorariosRemote) {
        HorariosRemote = $db.HorariosRemote;
      }
      if (!Horarios) {
        Horarios = $db.Horarios;
      }
    }

    function getHorarioApi(
      idInicio: string,
      idTermino: string
    ): Promise<[IHorarios['H'], IHorarios['TRF']]> {
      return $fetch<IHorarios>(
        `${BASE_URL}/horarios${version}/${servicioId.value}>${idInicio}>${idTermino}`
      ).then(({ H, TRF }) => {
        return [H, TRF];
      });
    }

    function getHorarioPouch(
      idSalida: string,
      idLlegada: string
    ): Promise<[IHorarios['H'], IHorarios['TRF']]> {
      if (Horarios) {
        return Horarios.get(
          `${servicioId.value}>${idSalida}>${idLlegada}`
        ).then((d) => {
          return [d.H, d.TRF];
        });
      }
      return Promise.reject(new Error('No horario iniciado'));
    }

    async function getHorario({
      salida: sal,
      llegada: lle,
      external = false,
    }: HorarioAPI = {}) {
      if (isBuscandoHorario.value) return false;
      isBuscandoHorario.value = true;

      let H: IHorarios['H'] = [];
      let T: IHorarios['TRF'] = { tpo: [] };

      const horarioSalida = sal || salida.value;
      const horarioLlegada = lle || llegada.value;

      if (!external && Horarios) {
        const HorarioData: [IHorarios['H'], IHorarios['TRF']] | undefined =
          await getHorarioPouch(horarioSalida, horarioLlegada)
            .catch((e: Error) => {
              if (e.message === 'missing') {
                return getHorarioApi(horarioSalida, horarioLlegada);
              }
              return undefined;
            })
            .finally(() => {
              if (sal) {
                salida.value = sal;
              }
              if (lle) {
                llegada.value = lle;
              }
            });

        if (HorarioData) {
          [H, T] = HorarioData;
        }
      } else {
        [H, T] = await getHorarioApi(horarioSalida, horarioLlegada)
          .catch((e) => {
            return [];
          })
          .finally(() => {
            if (sal) {
              salida.value = sal;
            }
            if (lle) {
              llegada.value = lle;
            }
          });
      }

      if (H) {
        horarios.value = H;
      }
      if (T) {
        tarifas.value = T.tpo.map((t: ITarifa) =>
          Object.assign({}, t, {
            tipo: t.tipo
              .replace(/(victoria\stemuco|biotren|corto\slaja)/gi, '')
              .replace(/(.*)\/(.*)/gi, '$1 / $2'),
          })
        );
      }

      isBuscandoHorario.value = false;

      return H;
    }

    function updateFn(percent: number) {
      initLoad.value = percent;
    }

    async function getAllHorarios() {
      if (!Horarios) return;

      await Horarios.compact().catch((e) => console.log(e, 'Compact err'));

      return Promise.resolve(Horarios.allDocs({ include_docs: true })).catch(
        () => {
          return Promise.reject(Horarios?.allDocs({ include_docs: true }));
        }
      );
    }

    async function syncHorarios() {
      if (!Horarios || !HorariosRemote) return;

      const docs = await PromiseReplicate(
        Horarios,
        HorariosRemote,
        updateFn
      ).then((d) => d.docs_written);

      if (docs) {
        await Horarios.compact().catch((e) => console.log(e, 'Compact err'));
        return Promise.resolve(Horarios.allDocs({ include_docs: true }));
      }
      return Promise.reject(Horarios.allDocs({ include_docs: true }));
    }

    function cambiarRuta({
      salida: salidaNew,
      llegada: llegadaNew,
      cambiarRuta = true,
    }: { salida?: string; llegada?: string; cambiarRuta?: boolean } = {}) {
      if (!llegadaNew && salidaNew == llegada.value) {
        llegada.value = salida.value;
      } else {
        salida.value = salidaNew || salida.value;
      }
      if (llegadaNew == salida.value) {
        salida.value = llegada.value;
      } else {
        llegada.value = llegadaNew || llegada.value;
      }

      const sal = parseInt(salida.value, 10);
      let lle = parseInt(llegada.value, 10);

      if (sal == lle) {
        lle = 16;
      }

      const estSalida = servicios.estacionesId.get(String(sal));
      const estLlegada = servicios.estacionesId.get(String(lle));

      if (!estSalida || !estLlegada)
        return Promise.reject(new Error('Estaciones no encontradas'));

      return getHorario({
        salida: estSalida?.id,
        llegada: estLlegada?.id,
      }).then(() => {
        return getHorarioPouch(estLlegada?.id, estSalida?.id)
          .then(([H, T]) => {
            horariosVuelta.value = H;
            console.log({ h: horariosVuelta.value, H, T });
          })
          .finally(() => {
            if (cambiarRuta) {
              navigateTo({
                params: {
                  idsalida: estSalida?.slug,
                  idllegada: estLlegada?.slug,
                },
              });
            }
          });
      });
    }

    const estacionSalida = computed<IEstacion | undefined>(() => {
      return (
        servicios.estacionesId.get(salida.value) ||
        servicios.estaciones.find((e) => String(e.id) === String(salida.value))
      );
    });

    const estacionLlegada = computed<IEstacion | undefined>(() => {
      return (
        servicios.estacionesId.get(llegada.value) ||
        servicios.estaciones.find((e) => String(e.id) === String(llegada.value))
      );
    });

    const tarifas = ref<ITarifa[]>([]);

    return {
      // State
      salida,
      llegada,
      horarios,
      tarifas,
      loading,
      initLoad,

      // Actions
      initDB,
      getHorarioApi,
      getHorarioPouch,
      getAllHorarios,
      getHorario,
      updateFn,
      syncHorarios,
      cambiarRuta,

      // Getters
      estacionSalida,
      estacionLlegada,
      isLoadingHorarios: computed(() => isBuscandoHorario.value),
      horariosVuelta: computed<Map<Horario['S'], Horario>>(() =>
        horariosVuelta.value.reduce((init, horario) => {
          init.set(horario.S, horario);
          return init;
        }, new Map())
      ),
    };
  }
);

export type THorariosStore = typeof useHorarioStore;
